import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nicht gefunden" />
    <section>
      <Container>
        <Row>
          <Col className="col-lg-12 col-12 py-5 mt-5 mb-3 text-center">
            <h1 className="mb-4">Error 404</h1>
          </Col>

          <Col className="col-12 mb-4" data-aos="fade-up">
            Die von Ihnen angeforderte Seite existiert leider nicht.
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default NotFoundPage
